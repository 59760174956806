body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 20px !important;
}

h3 {
  font-size: 18px !important;
}

.invoice table tbody td .label {
  display: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr th {
  font-size: 18px;
  padding: 12px;
  border: 1px solid #eeeeee;
  text-align: left;
  background-color: rgba(166, 146, 221, 0.2);
}

tr td {
  border: 1px solid #eeeeee;
  text-align: left;
}

input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  width: 91%;
  padding: 12px 12px;
  font-family: 'Poppins', sans-serif;
}

input:hover {
  background-color: #fffaf3;
}

input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}

.table-style {
  width: '100%' !important;
  /* borderCollapse: 'collapse'; */
}

table h6 {
  font-size: 16px;
  font-weight: 600;
  color: #111;
}
table span {
  font-size: 14px;
  font-weight: 400;
  color: #111;
}

table td, table th {
  border: 1px solid black !important;
  padding: 15px !important;
}

.table-style-reinforcments td {
  border: 1px solid black !important;
  padding: 0px !important;
}

.upload-button {
  background-color: #e5d2ff; /* Custom background color */
  color: #ffffff; /* Custom text color */
  padding: 10px 20px; /* Custom padding */
  border-radius: 8px; /* Custom border radius */
  font-size: 16px; /* Custom font size */
  font-weight: bold; /* Bold font */
  text-transform: none; /* Disable uppercase text */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 
              0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
              0px 1px 18px 0px rgba(0, 0, 0, 0.12); /* Custom shadow */
  transition: background-color 0.3s; /* Smooth transition for background color change */
}

.upload-button:hover {
  background-color: #3700b3; /* Darken the background color on hover */
}

.button {
  background-color: #3700b3; /* Custom background color */
  color: #ffffff; /* Custom text color */
  padding: 10px 20px; /* Custom padding */
  font-size: 14px; /* Custom font size */
  font-weight: bold; /* Bold font */
  text-transform: none; /* Disable uppercase text */
  text-decoration: none;
  display: inline-block;
}


@media (max-width: 600px) {
  .invoice table {
      width: 100%;
  }
  .invoice table thead {
      display: none;
  }
  .invoice table tbody tr {
      display: block;
      margin-bottom: 20px;
  }
  .invoice table tbody td {
      display: block;
      width: 100%;
  }
  .invoice table tbody td .label {
      display: block;
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 5px;
  }
}